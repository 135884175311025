<template>
  <v-app id="login" class="secondary">
    <v-content class="grad1">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-layout align-center justify-center>
              <img
                src="@/assets/pumas-brand-header.svg"
                alt="Vue Material Admin"
                width="180"
                height="180"
              />
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col sm="12" lg="6">
            <v-layout align-center justify-center>
              <v-img
                lazy-src="@/assets/slider_3.svg"
                src="@/assets/slider_3.svg"
              ></v-img>
            </v-layout>
          </v-col>
          <v-col sm="12" lg="5">
            <v-layout align-center justify-center class="text-center">
              <v-flex xs10>
                <div class="layout column align-center">
                  <h2>Lyv</h2>
                  <h4 class="flex my-4 theme--text">Precision Healthcare</h4>
                </div>

                <v-form ref="form" lazy-validation>
                  <v-text-field
                    append-icon="person"
                    name="login"
                    label="Email"
                    type="text"
                    v-model="email"
                    :error="error"
                    :rules="emailRules"
                    outlined
                  />
                </v-form>
                <v-progress-linear
                  :active="loading"
                  :indeterminate="loading"
                  absolute
                  bottom
                  color="deep-purple accent-4"
                ></v-progress-linear>

                <v-btn
                  class="theme--white"
                  color="#EF515B"
                  @click="
                    setPassword();
                    loading = true;
                  "
                  >Submit</v-btn
                >
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
      <!-- <v-snackbar
        v-model="showResult"
        :timeout="2000"
        top>
      </v-snackbar> -->
      <v-snackbar
        top
        v-model="ui.infoSnackbar.state"
        :timeout="ui.infoSnackbar.timeout || 6000"
        :color="ui.infoSnackbar.color"
      >
        {{ ui.infoSnackbar.info }}
      </v-snackbar>
    </v-content>
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from "../../components/Snackbar";
import { mapMutations } from "vuex";
export default {
  name: "login",
  data() {
    return {
      loading: false,
      email: "",
      error: false,
      showResult: false,
      result: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      ui: {
        retrieveLoading: false,
        infoSnackbar: {
          state: false,
          color: "error",
          info: null,
          timeout: 8000,
        },
      },
    };
  },
  watch: {
    loading(val) {
      if (!val) return;

      setTimeout(() => (this.loading = false), 8000);
    },
  },
  components: {
    Snackbar,
  },
  mounted() {
    this.ui.retrieveLoading = true;
  },

  methods: {
    setPassword() {
      const email = this.email;
      console.log(email);

      this.$store
        .dispatch("forgotPass", {
          email: email,
        })
        .then((res) => {
          console.log(res.data.message);
          if (res.status == 202) {
            this.setSnack(res.data.message);
            this.$router.push("Login");
          }
        })
        .catch(() => {
          this.$router.push("Login");
        });
    },
    ...mapMutations({
      setSnack: "snackbar/setSnack",
    }),
  },
};
</script>

<style scoped lang="scss">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.grad1 {
  background: linear-gradient(120deg, #c2c4c5, #c2c4c5, #ebb9a9 85%);
  background-size: 200% 200%;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
}
@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
v-h1 {
  font-size: 5rem;
  line-height: 3.65rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
</style>
